import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CategoryContentPageLink.module.scss';
import { FormattedMessage } from 'react-intl';
import { getPagePath } from '../../utils/appRoutes';
import { ARD_CATEGORY_CONTENT } from '../../utils/appRoutes.definitions';
import classNames from 'classnames';

const CategoryContentPageLink = ({ category, categoryPath, isHidden = false }) => {
  return (
    <div className={classNames(styles.wrapper, { [styles.hidden]: isHidden })}>
      <Link
        to={getPagePath(ARD_CATEGORY_CONTENT, categoryPath) + '#about'}
        className={styles.root}
        data-testid="CategoryContentPageLink"
      >
        <FormattedMessage id="common.about" /> {category.toLowerCase()}
      </Link>
      <Link to={getPagePath(ARD_CATEGORY_CONTENT, categoryPath) + '#qa'} className={styles.root}>
        <FormattedMessage id="common.qna" />
      </Link>
    </div>
  );
};

export default CategoryContentPageLink;
