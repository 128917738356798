import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import constructURL from '../../utils/constructURL';
import PageSectionHeader from '../PageSectionHeader';
import styles from './RelatedCategories.module.scss';

const RelatedCategories = ({ items = [], ignoreExistingQueryString = true }) => {
  if (!items.length) {
    return null;
  }

  return (
    <div className={classNames('container', styles.root)}>
      <PageSectionHeader>
        <span>
          <FormattedMessage id="common.breadcrumb.explore" defaultMessage="Explore" />
        </span>{' '}
        <FormattedMessage id="common.breadcrumb.relatedCategory" defaultMessage="related categories" />
      </PageSectionHeader>
      <div className={styles.breadcrumb}>
        {items.reverse().map((item) => (
          <Link
            key={`mobile_breadcrumb_item_${item.slug}`}
            className={styles.breadcrumbItem}
            to={constructURL(item.url, {
              ignoreExistingQueryString:
                item?.ignoreExistingQueryString !== undefined
                  ? item.ignoreExistingQueryString
                  : ignoreExistingQueryString,
            })}
          >
            {item.text}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RelatedCategories;
